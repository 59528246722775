body,
html {
  margin: 0;
  padding: 0;
  background-color: #2d3e4c;
}

h1.gradient {
  background: #ffae45;
  background: -webkit-linear-gradient(to right, #ffae45 0%, #6393c1 100%);
  background: -moz-linear-gradient(to right, #ffae45 0%, #6393c1 100%);
  background: linear-gradient(to right, #ffae45 0%, #6393c1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.current-words h1 {
  z-index: 2;
}

.cheese-carving-image {
  position: absolute;
  right: -150px;
  top: -80px;
}

@media screen and (max-width: 768px) {
  .mobile-overflow-hide {
    overflow-x: hidden;
  }
  .cheese-carving-image {
    width: 400px;
    position: absolute;
    right: -150;
    top: -20px;
  }
}
